<template>
	<phi-page color="#f2f2f2">
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1 v-if="credit.method">{{credit.method.name}} {{ credit.sequence ? '#' + credit.sequence : null }}</h1>
		</div>

		<phi-credit :credit="credit"></phi-credit>
	</phi-page>
</template>

<script>
import app from '../../../store/app.js'
import PhiCredit from '../../../components/Phi/Credit.vue';

export default{
	components: {PhiCredit},
	data() {
		return {
			credit: {}
		}
	},
	beforeRouteEnter(to, from, next) {
		app.api.get(`v3/credits/${to.params.creditId}`)
			.then(credit => next(vm => vm.credit = credit));
	}
}
</script>